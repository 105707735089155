/**
 * Buttons
 */

$btn-bg: $primary !default;
$btn-text-color: white !default;
$btn-font-weight: inherit !default;
$btn-font-size: 1rem !default;
$btn-padding-x: .75rem !default;
$btn-padding-y: .5rem !default;
$btn-border-color: transparent !default;
$btn-border-width: 1px !default;
$btn-border-radius: 0 !default;

$btn-hover-bg: darken($primary, 10%) !default;
$btn-hover-text-color: white !default;
$btn-hover-border-color: transparent !default;

$btn-sm: .875 !default;
$btn-lg: 1.25 !default;

.btn {
  display: inline-block;
  padding: $btn-padding-y $btn-padding-x;
  border: $btn-border-width solid transparent;
  background-color: $btn-bg;
  color: $btn-text-color;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  cursor: pointer;
  transition: $transition-base;
  user-select: none;
  text-decoration: none;
  border-radius: $btn-border-radius;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    background-color: $btn-hover-bg;
    color: $btn-hover-text-color;
    border-color: $btn-hover-border-color;
  }

  &:focus {
    outline: 0;
  }
}

// Size Variants
.btn-sm {
  font-size: $btn-font-size * $btn-sm;
  padding: ($btn-padding-y * $btn-sm) ($btn-padding-x * $btn-sm);
}
.btn-lg {
  font-size: $btn-font-size * $btn-lg;
  padding: ($btn-padding-y * $btn-lg) ($btn-padding-x * $btn-lg);
}

// Button Block
.btn-block {
  display: block;
  width: 100%;
}

// Button Outline
.btn-outline {
  border: 1px solid $btn-bg;
  background-color: transparent;
  color: $btn-bg;
  &:hover {
    border-color: $btn-bg;
    background-color: $btn-bg;
    color: $btn-hover-text-color;
  }
}

// Button Link
.btn-link {
  box-shadow: none;
  background: transparent;
  border: 0;
  color: black;
  text-shadow: none;
  text-transform: none;

  &:hover,
  &:focus {
    background: transparent;
    color: black;
  }
}
