/**
 * Header
 */

header {
  nav.navbar {
    display: flex;
    align-items: center;
    padding: 20px 15px 150px 20px;
    
    @include media-breakpoint-up(lg) {
      flex-direction: column;
      flex-flow: column !important;
      padding: 70px 20px 270px 20px;
		}

    a {
      text-transform: $text-transform;
      letter-spacing: $letter-spacing;
    }


    .navbar-brand {
      margin-right: 0;
      padding: 0;

      @include media-breakpoint-down(lg) {
        width: calc(100% - 96px);
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 70px;
        max-width: 600px;
        width: 100%;
      }

      @include media-breakpoint-down(lg) {
        margin-right: 40px;
      }

      img {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 600px;
        }
      }
    }

    .navbar-toggler {
      padding: 5px;
      font-size: 0;
      line-height: 0;

      &.collapsed {
        .navbar-toggler-icon {
          span {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              transform-origin: left center;
              transform: rotate(0deg);
              width: 100%;
              opacity: 1;
              left: 0;
            }

            &:nth-child(1) {
              top: 0;
            }
    
            &:nth-child(2) {
              top: 7px;
            }
    
            &:nth-child(3) {
              top: 14px;
            }
          }
        }
      }

      .navbar-toggler-icon {
        position: relative;
        width: 25px;
        height: 16px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        span {
          position: absolute;
          background-color: #672b2d;
          height: 2px;
          width: 100%;
          left: 0;
          opacity: 1;
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;

          &:nth-child(1) {
            transform: rotate(32deg);
            top: 7px;
            left: 0px;
          }

          &:nth-child(2) {
            width: 0%;
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-32deg);
            top: 7px;
            left: 0px;
          }
        }
      }
    }

    .navbar-collapse.collapse {
      max-height: 100%;
      overflow: visible;
    }

    .navbar-collapse {
      ul.navbar-nav {
        @include media-breakpoint-down(lg) {
          padding-top: 50px;
        }

        li.nav-item {
          padding: 0;

          @include media-breakpoint-up(lg) {
            padding: 0 40px;
          }

          @include media-breakpoint-down(lg) {
            margin-bottom: 10px;
          }

          a.nav-link,
          a.dropdown-item {
            font-size: rem(14px);
            line-height: rem(14px);
            color: $text-color;
          }
          a.dropdown-item.active, a.nav-link.active {
            text-decoration: underline;
            background-color: transparent;
          }

          a {
            &.nav-link {
              padding: 0;
            }

            &:hover {
              color: #000;
              background-color: transparent;
            }
          }

          &.dropdown {
            .nav-link.dropdown-toggle {
              &:after {
                display: none;
              }
            }

            .dropdown-menu {
              top: 45px;
              min-width: calc(100% - 80px);
              padding: 0 40px;
              margin: 0;
              border: none;
              background-color: transparent;

              .dropdown-item {
                padding: 10px 0;
              }
            }
          }
        }
      }
    }
  }
}

/**
 * Collapse
 * https://medium.com/dailyjs/mimicking-bootstraps-collapse-with-vanilla-javascript-b3bb389040e7
 */

 .collapse,
 .dropdown-menu {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: max-height .5s cubic-bezier(0, 1, 0, 1);

  &.show {
    max-height: 99em;
    transition: max-height .5s ease-in-out;
  }
}
