/*
** Global variables
*/

$body-background-color: #fffdf5;
$text-color: #411a1d;
$container-width: 934px;
$section-margin-bottom-md--large: 130px;
$section-margin-bottom-md--medium: 60px;
$text-transform: uppercase;
$letter-spacing: 2px;
