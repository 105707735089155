/**
 * Rich text
 */

.rich-text {
  margin-bottom: 60px;
  color: $text-color;

  p {
    font-size: rem(14px);
    line-height: rem(18px);
    text-transform: $text-transform;
    letter-spacing: $letter-spacing;
  }
}

.text-align-center {
  text-align: center;
}
