/**
* Grid
*/

// sections
section {
  width: 100%;
}

// containers
.container {

  // max-width of the grid
  @include media-breakpoint-up(xl) {
    max-width: $container-width !important;
  }
}

.mb--large {
  margin-bottom: $section-margin-bottom-md--large;
}

.mb--medium {
  margin-bottom: $section-margin-bottom-md--medium;
}
