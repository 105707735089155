* {box-sizing:border-box}

/* Slideshow container */
.image-slider {
  max-width: 700px;
  position: relative;
  margin: auto;
}
.image-slider__counter {
  text-align: center;
  padding-top: 18px
}

/* Hide the images by default */
.image-slider__slide {
  display: none;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: #411a1d;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: -200px;
  border-radius: 3px 0 0 3px;
  @include media-breakpoint-down(md){
    right: -14px;
    top: -40px;
  }
}
.prev {
  left: -200px;
  @include media-breakpoint-down(md){
    left: -14px;
    top: -40px;
  }
}


/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  opacity: 0.6;
  text-decoration: none;
  color: #411a1d;
}

/* Caption text */
.text {
  color: #411a1d;
  font-size: 15px;
  padding: 8px 12px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Fading animation */
.slider-fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
