/**
 * Footer
 */
 
footer {
  padding: 250px 20px 50px 20px;

  .footer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2, 
    p {
      font-size: rem(14px);
      text-transform: $text-transform;
      letter-spacing: $letter-spacing;
      color: $text-color;
    }

    h2 {
      margin-bottom: 55px;
    }

    p {
      margin-bottom: 70px;

      &:last-of-type {
        margin-bottom: 30px;
      }
    }

    img {
      max-width: 140px;
    }
  }
}
