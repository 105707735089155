#toolbar {
  z-index: 1;
}

#openseadragon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
