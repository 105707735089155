/**
 * Font Face
 */

@font-face {
  font-family: 'EngraGotReg';
  src: url('../assets/fonts/EngraversGothicRegular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
