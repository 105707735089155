/**
 * Artwork
 */

.artwork {
  margin-bottom: 130px;
  .artwork-img {
    margin-bottom: 12px;
    cursor: zoom-in;
  }

  .artwork-description {
    h2,
    p {
      text-transform: $text-transform;
      letter-spacing: $letter-spacing;
      color: $text-color;
      line-height: rem(12px);
    }

    h2 {
      font-size: rem(12px);
      margin-bottom: 2px;
    }

    p {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      font-size: rem(9px);
    }
  }
}
