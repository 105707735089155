/**
 * Transitions
 */

$transition-base: all .2s ease-in-out !default;

// Scroll snap effect
.scroll-snap-container {
  @include media-breakpoint-up(lg) {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
}

.scroll-snap-slide {
  @include media-breakpoint-up(lg) {
    width: 100%;
    // height: 100vh;

    /* align center */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    /* scroll-snap */
    scroll-snap-align: center;
    scroll-snap-stop: normal;
  }
}
