/**
 * Card
 */

 .card {
  border: none;
  background-color: unset;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .card__img {
    margin-bottom: 12px;

    img {
      height: 280px;
      width: 100%;
      object-fit: cover;
    }
  }

  .card__text {
    h2,
    p {
      text-transform: $text-transform;
      letter-spacing: $letter-spacing;
      color: $text-color;
      line-height: rem(12px);
    }

    h2 {
      font-size: rem(12px);
      margin-bottom: 2px;
    }

    p {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      font-size: rem(9px);
    }
  }
}
